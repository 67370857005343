.videocontainer{
    position: relative;
}

.overlay{
    position: absolute;
    top: 6px;
    left: 0px;
    height: 59px;
    width: 100%;
    background-color: rgb(4 4 4 / 0%);
}

.ytp-title{
    background-color: red;
    display: none !important;
    opacity:0 !important;
}
.ytp-title-channel{
    display: none !important;
}
.ytp-chrome-top-buttons{
    display: none !important;
}
.ytp-pause-overlay{
    display: none !important;
}

.iframe-wrapper .ytp-title{
    display: none !important;
  }