.paid-exam-module-section{
    padding: 15px;
    background-color: #fff;
    /* box-shadow: 0px 0px 5px 1px #979797bf;
    -webkit-box-shadow: 0px 0px 5px 1px #979797bf;
    -moz-box-shadow: 0px 0px 5px 1px #979797bf; */
}
.paid-exam-module-section .paid-exam-topbar{
    background: #0166DC;
    color: #fff;
    padding: 5px 15px;
    box-shadow: 0px 3px 4px -1px #ccc;
    display: flex !important;
    justify-content: space-between !important;
}
.paid-exam-module-section .paid-exam-topbar span{
    font-size: 25px;
    font-weight: 600;
}
.paid-exam-module-section .question-title{
    background: #0166DC;
    color: #fff;
    padding: 8px 10px;
    border-radius: 6px;
    margin-bottom: 10px;
    font-size: 20px;
}
.paid-exam-module-section .question-label{
    background: #fff;
    width: 100%;
    padding: 8px 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px -1px;
    cursor: pointer;
    font-size: 18px;
}
.question-title p{
    display: inline;
}
.paid-exam-module-section .select-answer{
    background-color: #9C28B1;
    color: #fff;
}