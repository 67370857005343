
body{
  background-color: #F9F9F9 !important;
  font-family: "solaimanlipi" !important;
}
.btn-primary{
  background-color: #0066DC !important;
}
.App {
  text-align: center;
}
.form-label{
  font-size: 17px;
}
.btn-primary{
  font-size: 18px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.shipping-form .alert {
  font-size: 17px;
}
.swal2-styled.swal2-confirm{
  background-color: #0066DC !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ytp-title{
  background-color: red;
  display: none !important;
}
.ytp-title-channel{
  display: none !important;
}
.ytp-chrome-top-buttons{
  display: none !important;
}

.ytp-pause-overlay{
  display: none !important;
}

.plyr.plyr--full-ui.plyr--video.plyr--youtube.plyr--fullscreen-enabled.plyr__poster-enabled.plyr--paused .plyr__video-wrapper {
  pointer-events: none;
}
.course-item-list-box{
  max-height: 480px;
  overflow-y: scroll;
}
.video_active{
  color: #0066DC;
}
