.name-box {
    padding: 20px;
    box-shadow: 0px 1px 5px -1px;
    background: #fff;
    border-radius: 10px;
    text-align: center;
    position: relative;
}
.name-box img{
    width: 100%;
}
.name-box h3{
    margin-top: 15px;
    margin-bottom: 0px;
}
.name-link{
    color: #383737;
}
.name-box:hover{
    color: #0066DC;
    border: 2px solid #0066DC;
    padding: 19px;
}
.name-price{
    position: absolute;
    top: 50%;
    right: 0px;
    background: #0066DC;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
}

@media only screen and (max-width: 668px) {
    .name-box{
        padding: 15px;
    }
    .name-box h3{
        font-size: 20px;
    }
    .name-price {
        top: 45%;
        padding: 4px 5px;
    }
}