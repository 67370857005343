.product-detail{
    padding: 25px 0px;
    background: #fff;
}
.product-image{
    display: flex;
    justify-content: center;
    align-items: center;
}
.product-image img{
    width: 90%;
}
.review-star{
    display: flex;
    align-items: center;
}
.review-count{
    margin-left: 10px;
    font-size: 16px;
}
.quantity-section{
    display: flex;
    align-items: center;
}
.quantity-section button:first-child,.quantity-section button:last-child{
    border: 1px solid #ccc;
    background: #F4F4F4 !important;
    color: #000;
    margin-right: 0px;
    border-radius: 0px;
    font-size: 16px;
}
.quantity-section .quantity-btn{
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    color: #000;
    border-radius: 0px;
    font-size: 16px;
    background: #fff !important;
    border-left: 0px !important;
    border-right: 0px !important;
    cursor: default;
}
.product-detail .product-decription h2{
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
}
.product-detail .side-products{
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 2px solid #0066dc;
    margin: 0px 15px;
}
.product-detail .side-products .side-product-title{
    font-size: 25px;
    font-weight: 600;
}
.product-detail .side-products .box{
    padding: 15px;
    border-bottom: 1px solid #ccc;
}
.product-detail .product-box .box-image img{
    width: 80px;
}
.box-content{
    padding-left: 30px;
}
.box-content h3{
    font-size: 20px;

}
.box-content h3 a{
    text-decoration: none;
    color: #000;
}
.read-btn{
    margin: 10px 0px;
}
.read-btn a{
    margin-bottom: 5px;
    display: block;
    text-align: left;
    font-size: 18px;
}
.review-box{
    margin-top: 15px;
}
.review-box h3{
    border-bottom: 1px solid #0066DC;
    color: #0066DC;
}
.review-box h5{
    color: #a9a9a9;
    font-family: sans-serif;
}
.review-box button{
    margin-top: 15px;
}
.review-rating-container span{
    color: #0066DC;
    font-size: 24px;
    margin-right: 5px;
    cursor: pointer;
}
.user-review-section{
    margin-top: 25px;
}
.user-review-section-title{
    font-size: 25px;
    border-bottom: 2px solid #0066DC !important;
    color: #0066DC;
}
.user-review-content-box{
    display: flex;
    margin-bottom: 20px;
}
.review-user-img {
    padding-right: 20px;
}
.review-content {
    padding: 0px 15px;
}
.details-price-cointainer{
    margin-bottom: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
}
.details-price-cointainer span{
    font-size: 20px;
}
.evidance-btn{
    display: block;
    width: 100%;
    text-align: left;
}
.details-price-cointainer span strike{
    color: #999;
    margin-right: 20px;
}
.user-name-date {
    display: flex;
    color: #747373;
}
.review-comment{
    color: #747373;
}
.reviewer-name{
    font-size: 20px;
    font-weight: bold;
}
.review-date{
    font-size: 18px;
}
.user-review-start span{
    color: #0066DC;
    font-size: 20px;
    margin-right: 5px;
    cursor: pointer;
}
.review-user{
    width: 50px;
    height: 50px;
    background: #afafaf;
    display: block;
    border-radius: 50%;
}
@media only screen and (max-width: 668px) {
    .product-image img{
        width: 50%;
    }
    .product-detail-conetent{
        text-align: center;
        margin-top: 25px;
        margin-bottom: 15px;
    }
    .product-detail .quantity-cart{
        justify-content: space-evenly;
    }
}
