.checkout-section{
    background: #F4F4F4;
    padding: 25px;
}
.checkout-section .shipping-form{
    background: #fff;
    padding: 40px 25px;
}
.checkout-section .checkout-summary{
    background: #fff;
    padding: 20px 25px;
}
.checkout-section .checkout-summary h3{
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
}
.checkout-section .checkout-summary .summery-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px;
}
.modal-btn{
    text-decoration: none;
    color: #fff;
}
.d-none{
    display: none;
}
.d-block{
    display: block;
}
.payment-bold{
    display: block;
    margin: 10px 0px;
}
.placeorder_btn button:hover{
    background-color: #fff !important;
    color: #0066DC !important;

}
.form-select{
    font-family: "solaimanlipi" !important;
}
textarea,
input,
select {
    font-size: 18px !important;
}
@media only screen and (max-width: 668px) {
    .placeorder_btn .placeorder_btn_div{
        position: fixed;
        bottom: 0;
        right: 0px;
        width: 100% !important;
        background: #fff;
        border-top: 1px solid #f4f4f4;
        display: flex;
        justify-content: center;
    }
    .placeorder_btn .placeorder_btn_div button{
        border-radius: 0px;
        width: 82%;
    }
}