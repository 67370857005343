.contact-section .page-title{
    text-align: center;
}
.contact-section .page-title span{
    padding: 5px 10px;
    background-color: #0066DC;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    font-size: 30px;
}
.contact-section form{
    margin: 15px;
    padding: 20px;
    background: #F9F9F9;
}
.title-logo img{
    width: 300px;
}