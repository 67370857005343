.paid-exam-topbar span a{
    color: #fff;
    text-decoration: none;
}
.wrong-answer{
    border: 2px solid #dd0c0c;
    box-shadow: none !important;
}
.currect-answer{
    border: 2px solid #32bf26;
    box-shadow: none !important;
}
.explanation-box{
    padding: 15px;
    box-shadow: 0px 0px 2px 1px #ccc inset;
    min-height: 100px;
}
.paid-exam-module-section .paid-exam-result-topbar{
    background: #0166DC;
    color: #fff;
    padding: 5px 15px;
    box-shadow: 0px 3px 4px -1px #ccc;
    text-align: center;
    display: block;
    font-size: 25px;
    font-weight: 600;
}