.courses-category-container{
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    margin-top: 30px;
}
.courses-category-item{
    display: flex;
    padding: 20px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    white-space: nowrap;
    transition: all .3s ease-in-out;
    background: #fff;
    color: #000;
    cursor: pointer;
}

.course-card-wrapper{
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
    min-width: 300px;
}

.card-img-wrapper{
    aspect-ratio: 2 / 1.2;
    overflow: hidden;
}

.card-img-wrapper img{
    width: 100%;
    aspect-ratio: 2 / 1.2;
    transition: all .3s ease-in-out;
}
.course-card-title{
    color: var(--gray-900, #204437);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    text-decoration: none;
    transition: all .3s ease-in-out;
    background: transparent;
}
/* .state_item{
    display: grid;
    grid-template-columns: 22px 1fr;
    grid-column-gap: 2px;
    column-gap: 2px;
} */
.state_item_item{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.state_item_item_title{
    font-size: 14px;
}
.course-card-bottom{
    display: grid;
    grid-template-columns: 1fr 150px;
    align-items: center;
}
.course_base_price{
    color: var(--secondary-900, #ff6652);
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    margin-right: 5px;
}
.course_price{
    color: var(--primary, #0066DC);
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
}
.instructor img{
    width:50px;
    height:50px;
    border-radius: 50%;
}
.course-category-active{
    background-color: #0066DC;
    color: #fff;
}
.courses-category-item:hover{
    background-color: #0066DC;
    color: #fff;
}

