.breadcrumb-container{
    background: #dbecff;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 15px;
}
.breadcrumb-container .breadcrumb-section{
    padding: 10px 15px;
    box-shadow: none;
    margin-bottom: 15px;
    width: fit-content;
    border-radius: 5px;
    margin-top: 10px;
}
.course-title{
    font-weight: 600;
    font-size: 42px;
    /* line-height: 56px; */
    color: #0a033c
}
.order1{
    order: 1;
}
.order2 {
    order: 2;
}
.course-left-container-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cecdd8;
}
.course-left-container-content-item{
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 3px solid transparent;
    transition: all .3s ease-in-out;
    font-family: 'Roboto', sans-serif !important;
}
.couse-left-item-active{
    border-bottom: 3px solid #0066DC;
    transition: all .3s ease-in-out;
    color: #0066DC;
}
.course-left-container-content-item:hover{
    cursor: pointer;
    color: #0066DC;
    transition: all .3s ease-in-out;
}
.course-detail-sidebar{
    position: sticky;
    position: -webkit-sticky;
    top: 10px;
    background: #fff;
    border-radius: 20px;
    padding: 10px;
}
.course-sidebar-video{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 15px;
}

.course-video-overlay{
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, rgba(80, 216, 118, .16) 0, rgba(32, 68, 55, .16) 100%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.course-sidebar-icon{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5ca8ff;
    transition: all .3s ease-in-out;
}
.course-sidebar-icon:hover{
    color: #0066DC;
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.course-sidebar-price{
    color: #0066DC;
    text-align: right;
    font-size: 47px;
    font-weight: 700;
    line-height: 30px;
}
.course-sidebar-base-price{
    color: var(--secondary-900, #ff6652);
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
    margin-right: 5px;
}
.course-sidebar-buy-button {
    display: flex;
    padding: 19px 30px;
    border-radius: 8px;
    background: var(--gradient, linear-gradient(135deg, #0066DC 0, #046feb 100%));
    box-shadow: 0 4px 20px 0 #6fb2ff;
    color: #fff;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 14px;
    outline: none;
    border: none;
    width: 100%;
}
.course-sidebar-title{
    color: var(--grey-600, #6c688a);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}
.course-sidebar-value{
    color: var(--grey-900, #204437);
    text-align: right;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
}
.course-content{
    font-size: 17px;
}
.course-content p,ul{
    background-color: transparent !important;
}

.course-detail-modal .modal-content{
    border-radius: 15px;
}

.routine-button{
    background: linear-gradient(101.04deg,#0066dc,#0066DC) !important;
    border-radius: 8px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 30px;
    border: none;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    transition: all .3s ease-in-out;
    text-decoration: none;
}
.routine-button:hover{
    box-shadow: 0 4px 12px rgba(0, 102, 220, .8);
    transition: all .3s ease-in-out;
    color: #fff;
}
.lecture-download-link{
    text-decoration: none;
    color: #000;
}

.lecture-item{
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-gap: 10px;
    gap: 10px;
    grid-row-gap: 0;
    row-gap: 0;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid;
    cursor: pointer;
}
.course-modal-header{
    position: relative;
    border: 0px;
}
.course-modal-header button{
    position: absolute;
    right: 0px;
    top: -20px;
}

/* .btn-custom-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #ff0000;
} */
.btn-custom-close{
    position: absolute;
    top: 4px !important;
    right: 0px;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: #ff0000;
}
.btn-custom-close:hover {
    color: #ad1f1f; /* Changes color on hover */
}
.modal-content{
    background: transparent;
    border: 0px;
}
.course-modal-header {
    position: relative;
    border: 0px;
    height: 50px;
    width: 100%;
    background: transparent;
}


@media only screen and (max-width: 500px) {
    .course-left-container-content{
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}