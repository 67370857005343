
.navbar{
    background-color: #fff !important;
    box-shadow: 0px 1px 1px 0px #ccc;
    margin-bottom: 2px;
}
.navbar-container{
    position: relative;
}
.navbar-nav{
    margin-right: 20px;
}
.navbar-nav .nav-link{
    color: #0066DC;
    font-size: 20px;
    padding-left: 12px !important;
    padding-right: 12px !important;
}
.navbar-nav .nav-link:hover{
    background-color: #0066DC;
    color: #fff;
}
.navbar-nav .auth-link {
    background: #fff;
    border: 1px solid #0066DC;
    border-radius: 10px;
    margin: 0px !important;
}
.navbar-nav .auth-link .nav-link{
    color: #0066DC;
}
.navbar-nav .auth-link:hover{
    background-color: #0066DC !important;
    border: 1px solid #0066DC;
}
.navbar-nav .auth-link:hover .nav-link{
    color: #fff !important;
}
.navbar-nav .auth-link .nav-link:hover{
    background-color: transparent !important;
}
.navbar-nav .auth-link .nav-link:first-child{
    padding-right: 0px !important;
}
.navbar-nav .auth-link .nav-link:last-child{
    padding-left: 0px !important;
}
.navbar-nav .cart-icon{
    border-radius: 10px;
    /* border: 1px solid #0066DC; */
    padding: 0px !important;
    position: relative;
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar-nav .cart-icon img{
    margin-bottom: 8px;
    width: 32px;
}
.notification{
    color: red !important;
    position: relative;
    margin-right: 15px;
}
.notification_number{
    color: red;
    font-size: 16px;
    position: absolute;
    top: 25%;
    right: 7%;
}
.notification:hover{
    color: #fff !important;
}
.notification:hover .notification_number{
    color: #fff !important;
}
.navbar-nav .cart-icon .cart-count{
    position: absolute;
    top: 10px;
    color: #0066DC;
}
.navbar-nav .cart-icon:hover{
    background-color: transparent !important;
}
.cart-icon-res{
    position: relative;
    margin-right: 15px;
    margin-bottom: 6px;
    display: none;
    justify-content: center;
    align-items: center;
}
.cart-icon-res img{
    width:35px !important;
}
.cart-icon-res .cart-count-res{
    position: absolute;
    top: 15px;
    color: #0066DC;
}
.toggle-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
#book_navbar{
    position: fixed;
    top:0;
    width: 100%;
    z-index: 9;
}
.slider-section{
    padding-top: 158px;
}
.top-banner-img{
    display: block;
}
.navbar-close-btn{
    display: none;
    position: absolute;
    bottom: -1px;
    right: 0px;
    color: red;
    border: 1px solid #ccc;
    padding: 2px 8px;
    font-weight: bold;
    font-size: 16px;
}
.notification-res{
    font-size: 20px;
    padding: 0px 8px;
    color: red;
    display: none;
    margin-right: 25px;
    position: relative;
}
.notification_res_number{
    color: red;
    font-size: 16px;
    position: absolute;
    top: 8%;
    right: -8%;
}
.notification-res:hover{
    color: red !important;
}
@media only screen and (max-width: 991px) {
    .navbar-close-btn{
        display: block;
    }
    .navbar-nav .cart-icon{
        display: none;
    }
    .notification{
        display: none;
    }
    .cart-icon-res{
        display: flex;
    }
    .slider-section{
        padding-top: 101px;
    }
    .notification-res{
        display: flex;
    }
}
@media only screen and (max-width: 668px) {
    .navbar-nav .auth-link{
        display: flex !important;
        flex-direction: row !important;
        width: 180px;
    }
    .navbar-nav .cart-icon{
        width: 45px;
        margin: 5px 0px;
    }
}