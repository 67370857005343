.page-title{
    background: #0066DC;
    color: #fff;
    padding: 5px;
    border-radius: 10px;
}
.paid-exam-topbar{
    background: #0066DC;
    color: #fff;
    padding: 5px;
    border-radius: 10px;
}