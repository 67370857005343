.category-box {
    padding: 20px;
    box-shadow: 0px 1px 5px -1px;
    background: #fff;
    border-radius: 10px;
    text-align: center;
}
.category-box img{
    width: 100%;
}
.category-box h3{
    margin-top: 15px;
    margin-bottom: 0px;
}
.category-link{
    color: #383737;
}
.category-box:hover{
    color: #0066DC;
    border: 2px solid #0066DC;
    padding: 19px;
}
@media only screen and (max-width: 668px) {
    .category-box{
        padding: 12px;
    }
}