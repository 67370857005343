@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@font-face {
  font-family: "solaimanlipi";
  src: local("solaimanlipi"),
   url("./font/solaimanlipi/SolaimanLipi_Bold_10-03-12.ttf") format("truetype");
}
/* font-family: Hind Siliguri Regular; */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
