.cart-section{
    background-color: #F4F4F4;
}
.cart-section .selected-items h3{
    font-size: 18px;
    margin: 0px;
}
.item-price{
    font-size: 18px;
}
.cart-section .selected-items{
    padding: 10px;
}
.summery-item span{
    font-size: 17px;
}
.cart-section .selected-items .total-price{
    /* color:#0066DC; */
    margin-left: 5px;
    margin-right: 10px;
    font-weight: 600;
}
.cart-section .cart-items .item-trash svg{
    color:crimson;
}
.cart-section .cart-items .item-trash{
    cursor: pointer;
}
.cart-section .cart-items .item-image img{
    width: 75px;
}
.cart-section .item-title h3{
    font-size: 20px;
}
.cart-section .item-quantity button:first-child, .item-quantity button:last-child{
    border: 1px solid #ccc;
    background: #F4F4F4 !important;
    color: #000;
    margin-right: 0px;
    border-radius: 0px;
    font-size: 16px;
}
.cart-section .quantity-btn{
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    color: #000;
    border-radius: 0px;
    font-size: 16px;
    background: #fff !important;
    border-left: 0px !important;
    border-right: 0px !important;
    /* cursor: default; */
    line-height: 31px;
    width: 50px !important;
    text-align: center;
}
.cart-section .item-quantity button:hover{
    color: #000;
    border: 1px solid #ccc;
}
.cart-section .checkout-btn button{
    width: 150px;
    border-radius: 0;
    font-size: 18px;
}
.cart-section .checkout-btn a{
    text-decoration: none;
    color: #fff;
}
.cart-section .checkout-summary{
    background: #fff;
    padding: 20px 25px;
}
.cart-section .checkout-summary h3{
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
}
.cart-section .checkout-summary .summery-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px;
}
.cart-section .checkout-btn:hover button{
    background-color: #fff !important;
    color: #0066DC !important;
}
.cart-section .checkout-btn:hover button a{
    color: #0066DC !important;
}
@media only screen and (max-width: 991px) {

    .cart-section {
        padding: 0px !important;
    }
    .cart-section .selected-items{
        flex-direction: column;
    }
    .cart-section .selected-items h3{
        margin-top: 10px;
    }
    .cart-section .cart-items{
        text-align: center;
        padding: 15px 5px !important;
    }
    .cart-section .cart-items .item-image img {
        width: 60px;
    }
    .cart-section .item-title h3 {
        font-size: 16px;
    }
    .checkout-btn .checkout-btn-div{
        position: fixed;
        bottom: 0;
        right: 0px;
        width: 100% !important;
        background: #fff;
        border-top: 1px solid #f4f4f4;
        display: flex;
        justify-content: center;
    }
    .checkout-btn .checkout-btn-div a{
        border-radius: 0px;
        width: 94%;
    }
}

@media only screen and (max-width: 500px) {
    .cart-section .item-title{
        max-width: 30%;
    }
    .cart-section .item-quantity button:first-child, .item-quantity button:last-child {
        font-size: 14px;
        padding: 6px 7px;
    }

    .cart-section .quantity-btn {
        font-size: 15px !important;
        width: 38px !important;
    }

    .item-price {
        font-size: 15px;
    }
}