.grid-container{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    background-color: #fff;
    padding: 15px 0px;
    box-shadow: 0px 1px 1px 0px #ccc;
}
.grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px 20px;
    font-size: 30px;
    text-align: center;
}
.grid-item button{
    width: 100%;
    font-size: 22px;
}
.category-button button:hover{
    background-color: #fff !important;
    border-color: #0066DC;
    color: #0066DC;
}
.category-button button a{
    color: #fff;
    text-decoration: none;
}
.category-button button:hover a{
    color: #0066DC !important;
}
@media only screen and (max-width: 668px) {
    .grid-container{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .grid-item {
        padding: 3px;
    }
    .grid-item button{
        font-size: 16px;
    }
}