.footer-section{
    background-color: #fff;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 2px 0px #ccc;
    margin-top: 10px;
}
.download-section .logo-image{
    width:126px
}
.download-section h3{
    font-size: 19px;
    font-weight: 500;
    margin-top: 15px;
}
.download-section .store-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.download-section .store-section img{
    width: 160px;
    height: auto;
}
.contact-section h3{
    text-align: center;
    font-size: 22px;
    font-weight: 600;
}
.contact-section ul li{
    list-style: none;
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}
.contact-section ul li svg{
    padding: 5px;
    background-color: #0066DC;
    color: #fff;
    margin-right: 8px;
    border-radius: 50px;
}
.main-footer{
    padding: 15px;
    background: #F4F4F4;
}
.main-footer p{
    text-align: center;
    color: #383737;
    font-size: 16px;
    margin: 0px;
}
.main-footer a{
    text-decoration: none;
    padding: 5px;
    color: #0066DC;
    border-right: 1px solid #c1c0c0;
    font-size: 17px;
}
.main-footer a:last-child{
    border-right: none;
}
.main-footer a:hover{
    background-color: #0066DC;
    color: #fff;
    border-radius: 10px;
}
.contact-section span{
    font-size: 16px;
}
.contact-section img{
    width: 35px;
    margin-right: 5px;
}
.extra-link a{
    font-size: 18px;
}
@media only screen and (max-width: 668px) {
    .footer-section {
        flex-direction: column;
        align-items: center;
    }
    .footer-section .download-section{
        text-align: center;
    }
    .footer-section .extra-link{
        margin-top: 15px;
    }
    .footer-section .contact-section{
        margin-top: 25px;
    }
    .main-footer a {
        display: inline-block;
    }
  }