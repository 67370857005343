.home-section{
    background-color: #fff;
    box-shadow: 0px 1px 1px 0px #ccc;
    margin-bottom: 2px;
}
.home-section h1{
    color: #383737;
    font-weight: bold;
    margin-bottom: 0px !important;
    padding-top: 20px;
    padding-bottom: 5px;
    font-size: 35px;
}
@media only screen and (max-width: 668px) {
    .home-section h1 {
        font-size: 25px;
    }
}