.forget-section{
    padding: 20px 10px;
    background: #fff;
}
.forget-title{
    margin: 0;
    text-align: center;
}
.title-logo img{
    width: 300px;
}
.forget-section .forget-title span{
    font-size: 30px;
    font-weight: 600;
    font-family: 'Roboto';
    background: #0066DC;
    padding: 5px 10px;
    border-radius: 10px;
    color: #fff;
}
.forget-section .forget-from p{
    text-align: center;
}
.forget-section .forget-from{
    background: #fff;
}
.forget-section .forget-image{
    text-align: center;
    margin-bottom: 20px;
}
.forget-section .forget-from .form-content{
    background: #F9F9F9;
    padding: 25px 15px;
}
.forget-section .remember-me{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.forget-section .forget-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.forget-section .forget-btn a{
    text-decoration: none;
    background: #0066DC;
    padding: 7px 8px;
    border-radius: 5px;
    color: #fff;
}
.forget-section .guid-content h2{
    font-size: 30px !important;
}