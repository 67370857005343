.course-card-wrapper{
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
    min-width: 300px;
    position: relative;
}

.course-status{
    position: absolute;
    top: 50%;
    right: 0px;
    background: #0066DC;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
}
@media only screen and (max-width: 668px) {

    .course-status {
        top: 45%;
        padding: 4px 5px;
    }
}