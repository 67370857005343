.leader-board-section .exam-title{
    padding: 15px;
    text-align: center;
    background: #0066DC;
    border-radius: 10px;
    color: #fff;
    margin: 10px 0px;
}
.leader-board-section .exam-title h3{
    font-weight: 600;
    font-size: 30px;
    margin: 0px;
}
.leader-list{
    padding: 15px;
    background: #fff;
    margin-bottom: 15px;
    box-shadow: 0px 1px 1px 0px #ccc;
}
.list-item{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 5px;
    background: #f3f3f3;
    padding: 5px;
    align-items: center;
    text-align: center;
}
.list-item span{
    font-size: 20px;
    display: flex;
    justify-content: center;
}
.profile-image-circle{
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #0066DC;
}
.list-item span img{
    width: 50px;
    border-radius: 50%;
}
.top-three-student{
    background-color: #0066DC;
    padding-bottom: 20px;
    box-shadow: 0px 1px 1px 0px #ccc;
    margin-bottom: 15px;
}
.top-student-image-circle{
    background: #0066DC;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 3px solid #fff;
}
.top-three-item span{
    display: flex;
    justify-content: center;
    align-items: center;
}
.top-three-item img{
    width: 70px;
    height: 70px;
    border-radius: 500%;
    border: 2px solid #fff;
}
.top-student-name{
    color: #fff;
    font-size: 18px;
}
.top-three-items{
    display: flex;
    justify-content: center;
    /* align-items: baseline; */
    padding-top: 50px;
}
.top-three-item{
    width: 140px;
    height: 157px;
    margin: 0px 3px;
}
.top-box{
    font-size: 26px;
    width: 100%;
    height: 80px;
    background: #fff;
    margin: auto;
    box-shadow: 0px 0px 3px 0px #ccc;
}
.first-box .top-box{
    height: 100px;
}
.second-box{
    margin-top: 10px;
}
.second-box .top-box{
    height: 90px;
}
.third-box{
    margin-top: 20px;
}
@media only screen and (max-width: 668px) {
    .top-three-item {
        width: 115px;
        height: 157px;
        padding: 0px 4px;
    }
}