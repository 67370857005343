.breadcrumb-section{
    padding: 15px;
    background: #fff;
    box-shadow: 0px 1px 2px 0px #ccc;
    margin-bottom: 2px;
}
.breadcrumb-section .breadcrumb{
    margin: 0px;
}
.breadcrumb-section .breadcrumb-item a{
    text-decoration: none;
    color: #333e48;
}