.page-title a{
    text-decoration: none;
    color: #fff;
}
.detail-section{
    position: relative;
}
.detail-section .details-video-section{
    position: absolute;
}
.detail-section .rowreverse {
    flex-direction: row-reverse;
    margin: 0px;
    display: flex;
    clear: both;
    flex-wrap: wrap;
    background: #0066DC;
}
.detail-section .col {
    padding: 15px;
}
.detail-section .colmodal {
    width: 40%;
    margin-bottom: 15px;
    position: relative;
}
.detail-section .coldetails {
    width: 60%;
    padding-bottom: 0;
}
.courseintro{
    background: #fff;
    padding: 15px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .5) 0 3px 7px;
}
.introvid {
    overflow: hidden;
    position: relative;
    border-radius: 4px;
}
.introvid button{
    font-size: 28px;
    line-height: 23px;
    padding: 10px 30px;
}
.introvid button a{ 
    text-decoration: none;
    color: #fff;
}
.detail-content-box {
    font-size: 25px;
    display: flex;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 20px;
    align-items: center;
    color: #212529;
}
.detail-content-box a{
    color: #212529;
    text-decoration: none;
}
.detail-content-box img{
    width: 36px;
    margin-right: 5px;
}
.detail-content-box span{
    background: #fff;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-radius: 8px;
}
.coldetails h2{
    color: #fff;
    font-size: 40px;
    text-align: center;
    margin-bottom: 40px;
    font-weight: bold;
}
.detail-content-box .sm-box{
    width: 220px;
}
.detail-content-box .big-box{
    width: 270px;
}
.name-details-section{
    background-color: #fff;
}
.details-content{
    width: 100%;
    min-height: 250px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 4px -2px inset;
    padding: 15px;
    font-size: 18px;
}
.name-details-section h3{
    margin: 15px 0px;
    font-size: 35px;
}
@media only screen and (max-width: 767px){
    .detail-section .rowreverse{
        flex-direction: column;
    }
    .colmodal {
        width: 100% !important;
    }
    .coldetails {
        width: 100% !important;
    }
    .coldetails h2{
        font-size: 35px;
        margin-top: 0px !important;
    }
    .detail-content-box{
        flex-direction: column;
        margin-bottom: 0px;
    }
    .detail-content-box .item-box{
        display: block;
        width: 90%;
        margin-bottom: 15px;
    }
}