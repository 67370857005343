.my-order-section{
    background-color: #fff;
}
.book-section{
    padding: 15px;
}
.book-section h2{
    background: #0066DC;
    color: #fff;
    text-align: center;
    padding: 6px;
    font-weight: 600;
}
.book-section .table-responsive{
    padding: 10px 35px;
}
.book-section table tr{
    border: none;
}
.book-section table td{
    border-right: 10px solid #fff;
    border-bottom: none;
    padding: 0px;
}
.book-section table td p{
    text-align: center;
    margin: 0px;
    border: 2px solid #0066DC;
    font-size: 25px;
    font-weight: 600;
    font-family: math;
    padding: 5px 10px;
    color: #0066DC;
} 
.book-section table th{
    background: #0066DC;
    color: #fff;
    border-right: 10px solid #fff;
    text-align: center;
    font-size: 25px;
    font-family: math;
    padding: 5px 10px;
}
.book-section table th:last-child{
    border-right: 0px;
}
.my-order-section .paid-course{
    padding: 15px;
}
.my-order-section .order-title{
    background: #0066DC;
    color: #fff;
    text-align: center;
    padding: 6px;
    font-weight: 600;
}
.my-order-section .paid-course .paid-course-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
    /* background-color: #0066DC; */
    padding: 6px 30px;
}
.my-order-section .paid-course .paid-course-container > div {
    text-align: center;
    padding: 5px;
    font-size: 25px;
    font-weight: 600;
  }
  .my-order-section .paid-course .paid-course-container .container-heading{
    background-color: #0066DC;
    color: #fff;
  }
  .my-order-section .paid-course .paid-course-container .container-value{
    background-color: #fff;
    color: #0066DC;
    border: 2px solid #0066DC;
  }
  .my-order-section .paid-course .paid-course-container > div  a{
    color: #0066DC;
    text-decoration: none;
  }
  .processing-status{
    background-color: #fff;
    color: #0066DC;
    border: 2px solid #0066DC;
    font-size: 25px !important;
    text-align: center;
  }
  select.form-control.processing-status:focus{
    color: #0066DC;
    border: 2px solid #0066DC;
    text-align: center;
  }
  @media only screen and (max-width: 668px) {
    .my-order-section .paid-course .paid-course-container{
        padding: 6px 0px;
    }
    .my-order-section .paid-course .paid-course-container{
        gap:6px;
    }
    .my-order-section .paid-course .paid-course-container > div{
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .processing-status {
        background-color: #fff;
        font-size: 16px !important;
        font-weight: bold;
    }
  }