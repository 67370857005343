.library-section{
    background: #fff;
    padding: 30px 25px;
    margin-top: 15px;
    box-shadow: 0px 0px 2px 0px #ccc;
}
.library-section table td,th{
    border:1px solid #000;
    /* text-align: center; */
}
.table table{
    width:100%;
}