.paid-exam-results-section{
    padding: 0px 12px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 1px #979797bf;
    -webkit-box-shadow: 0px 0px 5px 1px #979797bf;
    -moz-box-shadow: 0px 0px 5px 1px #979797bf;
}
.paid-exam-results-section h3{
    padding: 10px;
    background-color: #0166DC;
    color: #fff;
    text-align: center;
    font-size: 25px;
}