.login-section{
    background: #F4F4F4;
}
.login-title{
    margin: 0;
    text-align: center;
}
.login-section .login-title span{
    font-size: 30px;
    font-weight: 600;
    font-family: 'Roboto';
    background: #0066DC;
    padding: 5px 10px;
    border-radius: 10px;
    color: #fff;
}
.login-form-content{
    background: #F9F9F9;
    padding: 8px 15px;
}
.login-section .login-from p{
    text-align: center;
    /* font-family: "Hind Siliguri Regular"; */
}
.login-box{
    box-shadow: 0px 0px 2px 1px #ccc;
}
.login-section .login-from{
    padding: 20px 10px;
    background: #fff;
}
.login-section .login-image{
    text-align: center;
    margin-bottom: 20px;
}
.login-section .remember-me{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.login-section .signup-link{
    margin: 0;
    padding: 15px;
    font-size: 18px;
}
.title-logo{
    text-align: center;
    margin-bottom: 15px;
}
.title-logo img{
    width:300px
}
.signin-with{
    text-align: center;
    margin: 10px;
    font-weight: 600;
}
.signin-other{
    text-align: center;
}
.signin-other .other-btn{
    padding: 0px;
    margin: 0;
    background-color: #fff !important;
    border: none !important;
}
.signin-other img{
    width: 250px;
}
.p-relative{
    position: relative;
}
.password-eye{
    position: absolute;
    right: 15px;
    bottom: 6px;
    cursor: pointer;
}
@media only screen and (max-width: 668px) {
    .login-section .remember-me {
        flex-direction: column;
    }
    .login-section .signup-link {
        padding: 15px 8px;
        font-size: 17px;
    }
    .guid-content {
        text-align: center;
    }
}