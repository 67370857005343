
.register-section{
    background: #fff;
}
.register-title{
    margin: 0;
    text-align: center;
}
.register-form-content{
    background: #F9F9F9;
    padding: 25px 15px;
}
.register-section .form-label span{
    font-size: 14px;
    margin-left: 10px;
    color: #d32626;
}
.register-section .register-title span{
    font-size: 25px;
    font-weight: 600;
    background: #0066DC;
    padding: 5px 10px;
    border-radius: 10px;
    color: #fff;
}
.commont-shadow{
    box-shadow: 0px 0px 2px 1px #ccc;
}
.register-section .register-from p{
    text-align: center;
}
.register-section .register-from{
    padding: 20px 10px;
    background: #fff;
}
.title-logo img{
    width:300px;
}
.p-relative{
    position: relative;
}
.password-eye{
    position: absolute;
    right: 15px;
    bottom: 6px;
    cursor: pointer;
}
.register-section .register-image{
    text-align: center;
    margin-bottom: 20px;
}
.register-section .register-from .form-content{
    background: #F9F9F9;
    padding: 25px 15px;
}
.register-section .remember-me{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.register-section .signup-link{
    margin: 0;
    padding: 15px;
    font-size: 18px;
}
@media only screen and (max-width: 668px) {
    .register-section .remember-me {
        flex-direction: column;
    }
    .register-section .signup-link {
        padding: 15px 8px;
        font-size: 17px;
    }
    .guid-content {
        text-align: center;
    }
}
