.book{
    /* border: 1px solid #ccc; */
    padding: 15px;
    margin-bottom: 25px;
    text-align: center;
    border-radius: 15px;
    box-shadow:  0px 0px 2px 0px #000000b3;
}
.book img{
    width: 95%;
    transition: transform .2s;
}
.book img:hover {
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1); 
}
.book:hover button{
    background-color: #fff !important;
    border-color: #0066DC;
    color: #0066DC;
}
.book:hover .added-cart-btn a{
    color: #0066DC;
}
.book a{
    text-decoration: none;
}
.book h3{
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 5px;
}
.book p{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
    font-family: 'math';
}
.book button:hover{
    background-color: #fff !important;
    border-color: #0066DC;
    color: #0066DC;
}
.added-cart-btn a{
    text-decoration: none;
    color: #fff;
}
.added-cart-btn:hover a{
    color: #0066DC;
}
.product-price-cointainer{
    margin-bottom: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.product-price-cointainer span{
    font-size: 20px;
}
.product-price-cointainer span strike{
    color: #999;
    margin-right: 15px;
}
@media only screen and (max-width: 668px) {
    .book-container{
        padding: 0px 6px !important;
    }
    .book{

    }
    
    .book h3{
        font-size: 20px;
    }
}