
.news-content .slick-slide {
    margin: 0 20px;
  }
.news-slider-section{
    padding: 25px 0px;
}
.news-content{
    border-radius: 10px;
    padding: 15px;
    background: #fff;
    margin: 0px 10px;
    min-height: 389px;
}
.news-content a{
    text-decoration: none;
    color: #232c6a;
    display: flex;
    flex-direction: column;
}
.news-content .img-box{
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}
.news-content .img-box img{
    width: 90%;
    max-height: 150px;
}
.news-content .news-title{
    font-size: 17px;
    color: #232c6a;
    line-height: 150%;
    margin-bottom: 32px;
}
.news-content .publish-date{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
}
.news-content .news-icon-image{
    width: 80px;
    height: 50px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(35,44,106,.15)!important;
    border-radius: 4px!important;
}
.news-content .news-icon-image img{
    width: 100%;
    height: auto;
}
.news-content .date-show{
    font-size: 14px;
}