.my-paid-exam-list-section{
    padding: 15px;
    background-color: #fff;
    margin: 15px 0px;
    box-shadow: 0px 0px 3px 0px #ccc;
}
.page-title{
    background: #0066DC;
    color: #fff;
    padding: 5px;
    border-radius: 10px;
}