.receipt-section {
    background: #fff;
    padding: 10px 30px;
}
.receipt-section .clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.receipt-section a {
    color: #0066db;
    text-decoration: none;
}
.receipt-section header {
    padding: 10px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #AAAAAA;
}


.receipt-section #logo  {
    padding-top:35px;
    display: inline-block;
    float: left;
    width: 50%;
}
.receipt-section #logo  img{
    width: 100px
}


.receipt-section #details {
    margin-bottom: 10px;
    display: block;
}

.receipt-section #client {
    float: right;
    display: inline-block;
    text-align: left;
    padding-left: 150px;
    width: 50%;
}
.receipt-section .title-bold{
    text-align: right;
    padding: 0
}
.receipt-section .signature{
    width:100px;
    padding-bottom: 0; 
    margin-bottom: 0 ;
    margin-left:10px;
    text-align: right
}
.receipt-section .margin-left{
    margin-left: 50px
}
.receipt-section .margin-left hr{
    float: right;
    padding: 0; 
    margin:0
}
.receipt-section #company {
    float: left;
    text-align: left;
    width: 50%;
}
.receipt-section main{
    margin-bottom: 20px;
}
.receipt-section #client .to {
    color: #777777;
}

.receipt-section h2.name {
    font-size: 1.4em;
    font-weight: normal;
    margin: 0;
}

.receipt-section #invoice {
    padding-top:25px;
    float: right;
    text-align: right;
}

.receipt-section #invoice h1 {
    color: #0066db;
    font-size: 2.4em;
    line-height: 1em;
    font-weight: normal;
    margin: 0  0 10px 0;
}

.receipt-section #invoice .date {
    font-size: 1.1em;
    color: #777777;
}

.receipt-section table {
    text-align: center;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 20px;
}
.receipt-container{
    background: #c9c9c9;
}
.receipt-section table th{
    padding: 10px;
    background: #EEEEEE;
    text-align: center;
    border-bottom: 1px solid #FFFFFF;
}
.receipt-section table td {
    padding: 10px;
    background: #EEEEEE;
    text-align: center;
    border-bottom: 1px solid #FFFFFF;
}
.receipt-section .bottom{
    bottom: 90px;
}
.receipt-section table th {
    white-space: nowrap;
    font-weight: normal;
}


.receipt-section table td h3{
    color: #777777;
    font-size: 1.2em;
    font-weight: normal;
    margin: 0 0 0.2em 0;
}

.receipt-section table .no {
    color: #FFFFFF;
    font-size: 1.0em;
    background: #0066db;
}

.receipt-section table .desc {
    text-align: left;
}

.receipt-section table .unit {
    background: #DDDDDD;
}

.receipt-section table .qty {
}

.receipt-section table .total {
    background: #0066db;
    color: #FFFFFF;
}

.receipt-section table td.unit,
table td.qty,
table td.total {
    font-size: 1.2em;
}

.receipt-section table tbody tr:last-child td {
    border: none;
}

.receipt-section table tfoot td {
    padding: 10px 20px;
    background: #FFFFFF;
    border-bottom: none;
    font-size: 1.0em;
    white-space: nowrap;
    border-top: 1px solid #0066db;
}

.receipt-section table tfoot tr:first-child td {
    border-top: none;
}

.receipt-section table tfoot tr:last-child td {
    color: #0066db;
    font-size: 1.0em;
    border-top: 1px solid #0066db;
}

.receipt-section table tfoot tr td:first-child {
    border: none;
}

.receipt-section #thanks{
    font-size: 2em;
    margin-bottom: 10px;
}

.receipt-section #notices{
    padding-left: 6px;
    border-left: 6px solid #0066db;
    font-size: 20px
}
.receipt-section .notice-box{
    margin-top: 20px
}
.receipt-section #notices .notice {
    font-size: 1.2em;
    
}

.receipt-section footer {
    color: #0066db;
    bottom: 0;
    border-top: 1px solid #AAAAAA;
    text-align: center;
    padding: 10px 0;
    margin-bottom: 20px;
}
.receipt-section .text-center
{
    text-align: center !important;
}
.table-responsive{
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width: 668px) {
    .receipt-container .receipt-section #logo{
        width: 38%;
    }
    .receipt-container .receipt-section #invoice h1{
        font-size: 1.8em;
    }
    .receipt-container .receipt-section{
        padding: 10px 15px;
    }
    .receipt-container .receipt-section #client{
        padding-left: 0px;
    }
    #details{
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
    }
    .publisher-info #company,.publisher-info #client{
        float: none !important;
        width: 100% !important;
    }
    .receipt-section .margin-left{
        margin-left: 0px;
    }
    .receipt-section #thanks{
        font-size: 1.5em;
    }
    .publisher-info{
        margin-top: 15px;
    }
    .publisher-info .margin-left{
        text-align: center;
    }
}