.score-section{
    padding: 15px;
    background-color: #fff;
}
.obtain-score{
    height: auto;
    background: #0066DC;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    position: relative;
    padding-bottom: 50px;
    margin-bottom: 170px;
}
.obtain-parent-section{
    border: 18px solid #efefef;
    border-radius: 50%;
    margin: 10px;
    padding: 15px;
}
.obtain-content-section{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 0px solid #0066DC;
    border-radius: 50%;
    padding: 32px 10px;
    background: #fff;
}
.obtain-content-section span{
    color: #0066DC;
}
.obtain-title{
    padding: 5px 3px 0px 3px;
    font-size: 18px;
}
.obtain-marks{
    padding: 0px 3px 5px 3px;
    font-size: 20px;
    font-weight: 600;
}
.marks-detail-content{
    width: 95%;
    position: absolute;
    background: #fff;
    top: 80%;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 2px 1px #ccc;
}
.marks-items{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}
.marks-item{
    display: flex;
    flex-direction: column;
}
.marks-item span{
    font-size: 18px;
    font-weight: 600;
}
.font-23{
    font-size: 23px !important;
}
.score-button-section{
    padding: 15px;
}
.score-button-section img{
    width: 45px;
}
.score-button-section .row{
    justify-content: center;
    align-items: center;
    text-align: center;
}
.score-button-item{
    display: flex;
    flex-direction: column;
}
@media only screen and (max-width: 668px) {
    .score-section {
        padding: 15px 0px;
    }
    .marks-detail-content {
        width: 100%;
        padding: 20px 15px;
    }
}