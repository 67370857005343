.videotutorial{
    background-color: #F4F4F4;
    padding: 25px 0px;
    padding: 25px 0px 0px 0px;
    box-shadow: 0px 1px 1px 0px #ccc;
    margin-bottom: 2px;
}
.videoContent{
    background: #fff;
    padding: 15px 0px;
    margin: 0px;
}
.vide-title{
    text-align: center;
    margin-top: 15px;
    font-weight: 600;
}
.vide-title span{
    background: #0066DC;
    padding: 5px 10px;
    color: #fff;
    margin-top: 12px;
    border-radius: 10px;
}
.guid-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.guid-content h2{
    font-weight: 600;
    color: #383737;
    margin-bottom: 15px;
    font-size: 35px;
}
.guid-content p{
    color: #383737;
    font-size: 20px;
    text-align: center;
    margin-bottom: 5px;
}
@media only screen and (max-width: 668px) {

    .guid-content h2{
        font-size: 25px;
        margin-top: 15px;
    }
    .guid-content p{
        font-size: 17px;
        margin: 0;
    }
}