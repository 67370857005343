.policy-section{
    padding: 15px 35px;
}
.policy-section h1{
    text-align: center;
    margin: 15px 0px;
}
.policy-section h1 span{
    padding: 5px 10px;
    background-color: #0066DC;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    font-size: 30px;
}
.title-logo img{
    width: 300px;
}