.newscover-section{
    background-color: #F4F4F4;
    padding: 25px;
    box-shadow: 0px 1px 1px 0px #ccc;
    margin-bottom: 1px;
}
.newscover-content h3{
    font-size: 30px;
    font-weight: 600;
    color: #383737;
    margin-bottom: 16px;
}
.newscover-content p{
    font-size: 18px;
    margin-bottom: 5px;
    color: #383737;
}