
.paid-exam-title-section{
    background-color: #fff;
    margin: 15px 0px;
}
.paid-exam-title{
    padding: 15px;
}
.paid-exam-title .page-title{
    font-weight: bold;
}
.subject-box{
    text-align: center;
    border: 1px solid #7a7a7a;
    margin: 12px 0px;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}
.subject-box:hover{
    background-color: #0066DC;
}
.subject-box:hover a{
    color: #fff;
}
.subject-box a{
    color: #000;
    text-decoration: none;
    font-size: 20px;
}
@media only screen and (max-width: 668px) {
    .subject-box{
        margin: 8px 0px;
    }
}