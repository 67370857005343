.page-title{
    background: #0066DC;
    color: #fff;
    padding: 5px;
    border-radius: 10px;
}
.paid-exam-category-section{
    background: #fff;
    padding: 15px;
    box-shadow: 0px 0px 1px 1px #ccc;
    margin-bottom: 1px;
}
.videotutorial{
    padding: 10px 0px 0px 0px !important;
}